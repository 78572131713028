<template>
    <div class="container" style="max-width: 1261px !important;">
        <div class="panl-title">
                <img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" />
                <router-link :to="'/lib?lang='+$i18n.locale"
                    style="text-decoration: none !important;">
                    <div class="tl panl-title-text d-inline"> {{$t('pub_latest')}} </div>
                </router-link>
            </div>
            
      <div class="row mt-1">
        <div class=" col-6 col-md-3 col-lg-2" v-for="item in items" :key="item.id">
            
                <div class="card main-pub text-center">
                    <router-link :to="'/lib/'+item.cat+'?lang='+$i18n.locale" ><img :src="'https://alkafeel.net/publications/img/200/'+item.img" class="card-img-top" alt="عنوان الكتاب"></router-link>
                    <div class="card-body">
                    <h5 class="card-title">{{ item.title }}</h5>
                    <p v-if="item.ver" class="card-text">{{$t("pub_ver")}}: {{ item.ver }}</p>
                    </div>
                </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        items: [
          { id: 1, title: 'مواقف الأطفال', issue: 68, image: 'assets/img/pub/pub-4.png' },
          { id: 2, title: 'نشرة الخميس', issue: 32, image: 'assets/img/pub/pub-1.png' },
          { id: 3, title: 'بنات الهدى', issue: 3, image: 'assets/img/pub/pub-2.png' },
          { id: 4, title: 'المشكاة', issue: 229, image: 'assets/img/pub/pub-3.png' },
          { id: 5, title: 'صدى الروضتين', issue: 441, image: 'assets/img/pub/pub-5.png' },
          { id: 6, title: 'نشرة الخميس', issue: 32, image: 'assets/img/pub/pub-1.png' },

        ]
      };
    },
    mounted() {
      this.ini();
    },
    methods: {
        ini () {
            this.$axios.get('pub/latest/6')
            .then (d => {
                this.loading = false;
                this.items = d.data;
            })
        },
    }
  };
  </script>
  
  <style scoped>
  .card {
    margin-bottom: 20px;
  }
  .card img {
    height: 200px;
    object-fit: cover;
  }
  .card-title {
    font-size: 1.1rem;
  }
  .card-text {
    font-size: 0.9rem;
  }

  .main-pub {
    border: 1px solid #ececec;
    border-radius: 4px;
    padding: 12px 0 0 0;
    transition: transform 0.3s ease-in-out; 

  }

  .main-pub:hover {
     transform: scale(1.1);
  }
  </style>
  
